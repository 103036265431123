

function Contact() {
    const MAILING_ADDRESS = "frmadara@uwaterloo.ca";
    const GITHUB_ADDRESS = "https://github.com/NlSEMONO/";
    const LINKEDIN_ADDRESS = "https://www.linkedin.com/in/francis-madarang-b4876113a/";
    const ROW_CLASSES = "flex flex-row justify-between w-fit";
    const TEXT_CLASSES = "pt-8 ml-6 md:ml-12 md:pt-10 lg:pt-14";
    const LINKEDIN_CLASSES = "pt-8 ml-6 md:ml-12 md:pt-10 lg:pt-14 ";

    const logoClasses = "w-12 h-12 md:w-16 md:h-16 lg:w-24 lg:h-24 mt-6"

    return (
        <div id="contact" class="text-center bg-comp1 text-main2 text-xl md:text-2xl lg:text-3xl font-titles pt-24"> 
            <h1 class="font-titles text-6xl mb-8 text-bold"> Contact </h1>
            <div className="ml-[7.5%] md:ml-[15%]">
                <div className={ROW_CLASSES}> 
                    <a href={GITHUB_ADDRESS} target="blank">
                        <svg className={logoClasses} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_40_16)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.508 0.00522375C7.22055 0.00522375 0.49971 6.89436 0.49971 15.3919C0.49971 22.1908 4.79966 27.9565 10.7633 29.9921C11.5139 30.1346 11.7898 29.6575 11.7898 29.2509C11.7898 28.884 11.7748 27.672 11.7687 26.3873C7.59287 27.3182 6.71259 24.571 6.71259 24.571C6.03007 22.7922 5.04619 22.3191 5.04619 22.3191C3.6845 21.3632 5.14979 21.3843 5.14979 21.3843C6.65659 21.4922 7.44991 22.9689 7.44991 22.9689C8.78945 25.3213 10.9621 24.6403 11.818 24.2478C11.9532 23.2534 12.341 22.5741 12.7708 22.1896C9.43699 21.8017 5.93255 20.4818 5.93255 14.5854C5.93255 12.906 6.51978 11.5327 7.4787 10.4559C7.32252 10.0674 6.80842 8.5033 7.62331 6.38427C7.62331 6.38427 8.88358 5.97026 11.7521 7.96147C12.9487 7.62127 14.2322 7.44975 15.507 7.44407C16.7827 7.44975 18.0685 7.62069 19.2668 7.96147C22.1314 5.97141 23.3906 6.38427 23.3906 6.38427C24.2066 8.50387 23.6947 10.0691 23.5385 10.4559C24.5008 11.5327 25.083 12.906 25.083 14.5854C25.083 20.4954 21.5708 21.7949 18.2297 22.176C18.7682 22.653 19.2491 23.5896 19.2491 25.0242C19.2491 27.0819 19.2319 28.7403 19.2319 29.2463C19.2319 29.6558 19.5022 30.1346 20.2634 29.9846C26.2236 27.948 30.5181 22.1816 30.5181 15.3868C30.5181 6.89032 23.7983 3.05176e-05 15.5092 3.05176e-05L15.5081 0.00517567L15.508 0.00522375Z" fill="#5696CB"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_40_16">
                            <rect width="30.0178" height="30.0178" fill="white" transform="translate(0.49971 3.05176e-05)"/>
                            </clipPath>
                            </defs>
                        </svg> 
                    </a> 
                    <a href={GITHUB_ADDRESS} target="blank"> <p className={TEXT_CLASSES}> github.com/NlSEMONO/ </p> </a>
                </div> 
                <div className={ROW_CLASSES}> 
                    <a href={LINKEDIN_ADDRESS} target="">
                        <svg className={logoClasses} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    
                            <g clip-path="url(#clip0_40_19)">
                            <path d="M28.8944 0.000282288H1.12308C0.502759 0.000282288 7.62939e-06 0.503034 7.62939e-06 1.12335V28.8946C7.62939e-06 29.515 0.502759 30.0177 1.12308 30.0177H28.8947C29.5151 30.0177 30.0178 29.515 30.0178 28.8946V1.12335C30.0175 0.503034 29.5147 0.000282288 28.8944 0.000282288ZM7.97497 24.7008C7.97497 25.1144 7.63988 25.4494 7.22626 25.4494H4.22877C3.81551 25.4494 3.48006 25.1144 3.48006 24.7008V11.5993C3.48006 11.1857 3.81551 10.8506 4.22877 10.8506H7.22626C7.63988 10.8506 7.97497 11.1857 7.97497 11.5993V24.7008ZM7.97497 7.7981C7.97497 8.21179 7.63988 8.54681 7.22626 8.54681H4.22877C3.81551 8.54681 3.48006 8.21173 3.48006 7.7981V5.13643C3.48006 4.72274 3.81551 4.38772 4.22877 4.38772H7.22626C7.63988 4.38772 7.97497 4.72274 7.97497 5.13643V7.7981ZM26.5374 24.7008C26.5374 25.1144 26.202 25.4494 25.7887 25.4494H22.7912C22.3776 25.4494 22.0425 25.1144 22.0425 24.7008V19.1689C22.0425 17.3345 21.9467 16.1481 21.7543 15.6095C21.5622 15.0708 21.2504 14.6523 20.8184 14.3539C20.3864 14.0552 19.8667 13.9062 19.2588 13.9062C18.4802 13.9062 17.7816 14.1195 17.1632 14.5459C16.5447 14.9727 16.121 15.5376 15.8915 16.2414C15.6624 16.9452 15.5478 18.2464 15.5478 20.1444V24.7008C15.5478 25.1144 15.2124 25.4494 14.7991 25.4494H11.8016C11.3883 25.4494 11.0529 25.1144 11.0529 24.7008V11.5993C11.0529 11.1857 11.3883 10.8506 11.8016 10.8506H14.479C14.8927 10.8506 15.2278 11.1857 15.2278 11.5993V12.5974C15.2278 13.0111 15.4304 13.0777 15.7084 12.7718C17.107 11.2351 18.813 10.4666 20.8267 10.4666C21.8183 10.4666 22.7246 10.6451 23.5459 11.0023C24.367 11.3594 24.9883 11.8153 25.4095 12.3698C25.8307 12.9242 26.1242 13.5535 26.2893 14.2573C26.4544 14.961 26.5375 15.9688 26.5375 17.2806V24.7008H26.5374Z" fill="#5696CB"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_40_19">
                            <rect width="30.0178" height="30.0178" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg> 
                    </a>
                    <a href={LINKEDIN_ADDRESS} target="" className="text-left"> <p className={LINKEDIN_CLASSES}> linkedin.com/in/francis-madarang-b4876113a </p></a>
                </div>
                <div className={ROW_CLASSES}> 
                    <a href={MAILING_ADDRESS}> 
                        <svg className={logoClasses} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_40_23)">
                            <path d="M2.43765 25.5701C0.0352689 25.5701 2.39865 23.1324 2.39865 23.1324L8.52359 16.4012C9.1193 15.7467 10.2689 14.753 10.941 15.3012C11.613 15.8493 12.5106 16.96 13.1356 17.5866L14.4023 18.8543C15.0813 19.4553 15.6037 18.8543 15.6037 18.8543L16.8997 17.5776C17.5301 16.9562 18.433 15.8723 19.1061 15.368C19.7792 14.8637 20.924 15.8227 21.5181 16.4787L27.6072 23.2068C30.0106 25.5702 27.6462 25.5702 27.6462 25.5702H2.43765V25.5701ZM3.47731 20.0322C2.8816 20.6871 2.01137 21.8261 1.42322 22.4875C0.759155 23.2339 7.62939e-06 23.8006 7.62939e-06 22.4934L0.0288845 9.36151C0.0310453 8.47625 0.00649019 6.78813 0.611234 7.09104C0.83616 7.2038 1.14388 7.43825 1.56162 7.85657L6.51822 11.9119C7.20311 12.4724 8.16843 13.1582 8.4842 13.6151C8.68349 13.903 8.77484 14.238 8.48637 14.5275L3.47731 20.0322ZM16.8373 15.3668C16.153 15.9282 15.4092 16.7248 15.0294 16.7259C14.6496 16.7264 13.892 15.9481 13.2045 15.3903L2.41957 6.64178C1.73203 6.08458 0.547686 4.96221 1.13632 4.66136C1.56152 4.4439 2.30466 4.44763 2.30466 4.44763H27.6484C29.9301 4.44763 28.7698 5.57432 28.7698 5.57432L16.8373 15.3668ZM28.593 22.4875C28.0038 21.8267 27.133 20.6871 26.5378 20.0322L21.5316 14.5269C21.2416 14.2374 21.3323 13.9024 21.5316 13.6145C21.8473 13.1577 22.8132 12.4723 23.4987 11.9119L27.2159 8.8711C27.9007 8.31065 28.8014 7.39396 29.4057 7.09104C30.0099 6.78813 29.9837 8.47684 29.9859 9.36151L30.0179 22.4934C30.0183 23.8006 29.2581 23.2339 28.593 22.4875Z" fill="#5696CB"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_40_23">
                            <rect width="30.0178" height="30.0178" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg> 
                    </a>
                    <a href={MAILING_ADDRESS}> <p className={TEXT_CLASSES}> frmadara@uwaterloo.ca </p></a>
                </div>
                <div className={ROW_CLASSES}>  
                    <a href="FM_RESUME_SE.pdf" target="blank"> 
                        <svg className={logoClasses} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_40_26)" fill="#5696CB">
                            <path d="M2.50149 13.7582V27.5163H13.7582H25.0148V13.7582V9.53674e-06H13.7582H2.50149V13.7582ZM23.7641 13.7582V26.2656H13.7582H3.75223V13.7582V1.25075H13.7582H23.7641V13.7582Z" fill="#5696CB"/>
                            <path d="M9.49313 5.34067C8.99283 5.60333 8.73018 5.84097 8.44251 6.30374C8.0923 6.87908 8.06728 7.00416 8.06728 8.12982C8.06728 9.25549 8.0923 9.38056 8.44251 9.9559C9.43059 11.5694 11.7945 11.6944 12.8451 10.1935C13.3579 9.45561 13.3329 9.30552 12.67 9.13041C12.3323 9.03036 12.0697 9.00534 12.0446 9.08039C11.882 9.55567 11.6319 9.85584 11.2192 10.056C10.7939 10.2561 10.6938 10.2686 10.2936 10.1185C9.53065 9.81832 9.31802 9.40558 9.31802 8.14233C9.31802 6.89159 9.50564 6.46634 10.2061 6.17867C10.8189 5.91601 11.5569 6.12864 11.8195 6.62893C12.0321 7.04168 12.1072 7.06669 12.7826 6.86657L13.2579 6.7415L13.0202 6.27873C12.7325 5.70338 12.4449 5.44073 11.8445 5.19058C11.1316 4.8904 10.2811 4.95294 9.49313 5.34067Z" fill="#5696CB"/>
                            <path d="M13.7582 5.2031C13.7582 5.25313 14.2209 6.61644 14.7963 8.21738L15.8219 11.1316L16.5098 11.1066L17.1977 11.0691L18.2233 8.19237C18.7861 6.60393 19.2614 5.27814 19.2614 5.21561C19.2614 5.16558 18.9863 5.12805 18.6486 5.12805H18.0232L17.3103 7.31685C16.91 8.51756 16.5598 9.48063 16.5473 9.45562C16.5223 9.4306 16.1721 8.46753 15.7718 7.30434L15.0339 5.19059L14.396 5.15307C14.0458 5.12805 13.7582 5.15307 13.7582 5.2031Z" fill="#5696CB"/>
                            <path d="M6.25371 14.3835V15.0089H13.7582H21.2626V14.3835V13.7582H13.7582H6.25371V14.3835Z" fill="#5696CB"/>
                            <path d="M6.25371 18.1357V18.7611H13.7582H21.2626V18.1357V17.5104H13.7582H6.25371V18.1357Z" fill="#5696CB"/>
                            <path d="M6.25371 21.888V22.5133H13.7582H21.2626V21.888V21.2626H13.7582H6.25371V21.888Z" fill="#5696CB"/>
                            <path d="M26.2656 15.0089V28.7671H15.0089H3.75223V29.3924V30.0178H15.6343H27.5163V15.6343V1.25076H26.8909H26.2656V15.0089Z" fill="#5696CB"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_40_26">
                                <rect width="30.0178" height="30.0178" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <a href="FM_RESUME_SE.pdf" target="blank"> <p className={TEXT_CLASSES}> Resume </p></a>
                </div>
            </div>
        </div>
    );
}

export default Contact;