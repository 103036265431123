import huaweiImg from './huawei.jpg'

const EMPH = "$";
const EXPERIENCE = [
    {   
        prio: 1,
        title: "Software Engineer Intern", 
        desc: `I helped in ${EMPH}optimizing${EMPH} ${EMPH}mass${EMPH} ${EMPH}queriying${EMPH} to ${EMPH}cloud${EMPH} databases by creating a tool to view runtime information of various database operators. I also prototyped a ${EMPH}benchmarking${EMPH} tool for analyzing the performance of the deployed database as it gets loaded with queries throughout busy times of the day.`,
        link: '',
        img: huaweiImg
    },
]

function endPunc(word) {
    if (word.endsWith(".") || word.endsWith(",") || word.endsWith("!") || word.endsWith("?")) {
        return true;
    }
}

function generateCard(proj) {
    let words = proj.desc.split(" ");
    let emphClasses ="text-black inline-block pr-1 font-text text-xl";
    let defClasses ="inline-block pr-1 font-text text-xl";
    let wordsMap = words.map((word) => {
        if (word.startsWith(EMPH)) {
            let removeTail = 1;
            let newTail = "";
            if (endPunc(word)) {
                removeTail++;
                newTail = word[word.length - 1];
            }
            return <span className={emphClasses} >{word.substring(1, word.length - removeTail) + newTail + " "}</span>
        }
        return <p className={defClasses}> {word+" "} </p>;
    })
    return (
        <div className="font-titles text-main2 text-center"> 
            <h2 className="text-3xl font-bold mt-16 md:mt-0"> {proj.title} </h2> <br/>
            <i className="">{wordsMap}</i>
        </div>
    )
}

function Experience() {
    EXPERIENCE.sort((a, b) => b.prio - a.prio);
    let cards = EXPERIENCE.map((proj) => {
        return (
            <div className="md:items-start p-4 mt-16 flex justify-between flex-wrap">
                <a href={proj.link} className='w-11/12 min-w-72 h-72 sm:w-10/12 md:w-3/12 lg:h-[400px] lg:min-w-[350px] xl:min-w-[450px] border-[#c0c0c0]'>
                    <img src={proj.img} className='w-11/12 min-w-72 h-72 sm:w-10/12 md:w-3/12 lg:h-[400px] lg:min-w-[350px] xl:min-w-[450px] border-[#c0c0c0] border-solid border-4 object-cover rounded-3xl m-auto mt-5 md:m-0'/>
                </a>
                <a href={proj.link} target="_blank" rel="noreferrer" className="md:max-w-[50%] text-center">
                    {generateCard(proj)}
                </a>
            </div>
        )
    });
    return (
        <div id="experience" className="flex flex-col items-center justify-center bg-comp1 text-center">
            <div className='w-[80%]'> 
                <h1 className="font-titles text-main2 text-6xl text-bold"> Experience </h1>
                <div className="">
                    {cards}
                </div>
            </div>
        </div>
    )
}

export default Experience;
