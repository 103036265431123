function Sidebar({dimmed, setDimmed}) {
    
    return (
    dimmed ?
    <div className="fixed flex z-40 h-full w-3/12 min-w-fit bg-main2 flex-col justify-start right-0 font-titles text-2xl items-center text-main1"> 
        <a href="#projects" className="pt-8" onClick={() => { if (dimmed) setDimmed(false);} }> <p > Projects </p> </a>
        <a href="#contact" className="pt-16" onClick={() => { if (dimmed) setDimmed(false);} }> <p> Contact </p> </a>
        <a href="FM_RESUME_SE.pdf" className="pt-16" onClick={() => { if (dimmed) setDimmed(false);} }> <p> Resume </p> </a>
    </div> : null);
}

export default Sidebar;