import './App.css';
import Navbar from './Navbar.js';
import Sidebar from './Sidebar.js';
import Welcome from './Welcome.js';
import Triangle from './Triangle.js';
import Projects from './Projects.js';
import Contact from './Contact.js';
import {useState} from 'react';
import Experience from './Experience.js';

function App() {
  const [dimmed, setDimmed] = useState(false);
  return (
    <body>
      <Navbar dimTools={{dimmed, setDimmed}}/>
      <Sidebar dimmed={dimmed} setDimmed={setDimmed}/>
      <div onClick={() => {if (dimmed) setDimmed(false);}} className={dimmed ? "opacity-40" : "opacity-100"}>
          <Welcome/>
          <Triangle/>
          <Experience/>
          <Projects/>
          <Contact/>
      </div>
    </body>
    
    
  );
}

export default App;
