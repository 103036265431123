import tdImg from './Todo-screenie.png';
import sfImg from './scrap_fighter.png';
import dpImg from './deeppoker.png';
import ssImg from './sinnoh_stores copy.png';
import vnImg from './vid2notes.png';
import apImg from './aiplay4u.png';
import knnImg from './knnections.webp'

const EMPH = "$";
const PROJECTS = [
    {   
        prio: 1,
        title: "Todo App", 
        desc: `Simple Todo App created using ${EMPH}React${EMPH} and hosted a ${EMPH}Django${EMPH} server. Created to help me get organized in life while allowing me to practice full stack skills.`,
        link: '/',
        img: tdImg
    }, 
    {
        prio: 2,
        title: "Scrap Fighter",
        desc: `Street fighter-esque game created using ${EMPH}Java${EMPH} and its Swing library. Created as a group of 3.`,
        link: 'https://github.com/NlSEMONO/Grade12CPT--Scrap-Fighter',
        img: sfImg
    }, 
    {
        prio: 2,
        title: "DeepPoker",
        desc: `AI that makes optimal poker (Texas Hold Em) moves, implemented using ${EMPH}Python${EMPH}. Created as a group of 4.`,
        link: 'https://github.com/NlSEMONO/CSC111-Project',
        img: dpImg
    }, 
    {
        prio: 5,
        title: "Sinnoh Stores",
        desc: `Store app based on Pokemon's Sinnoh region created using ${EMPH}React${EMPH}, ${EMPH}NextJS${EMPH}, ${EMPH}Django${EMPH} and ${EMPH}TailwindCSS${EMPH}. [note: content may not load because API is down]`,
        link: 'https://sinnoh-store.vercel.app/',
        img: ssImg
    }, 
    {
        prio: 3,
        title: "Vid2Notes",
        desc: `Web app that takes a YouTube video and summarizes it or turns the video's content into notes. Created using ${EMPH}React${EMPH}, ${EMPH}Django${EMPH} and ${EMPH}TailwindCSS${EMPH}.`,
        link: 'https://github.com/NlSEMONO/Vid2NotesAPI',
        img: vnImg
    },
    {
        prio: 10,
        title: "AIPlay4U",
        desc: `Block coding AI tool for creating scripts that automate tasks that may require computer vision. Desktop app created using ${EMPH}Electron${EMPH}, ${EMPH}React${EMPH} and ${EMPH}TailwindCSS${EMPH}. Script execution is done in ${EMPH}C++${EMPH} which is complemented with ${EMPH}OpenCV's${EMPH} object detection framework.`,
        link: 'https://github.com/NlSEMONO/AIPlay4U-GUI',
        img: apImg
    },
    {
        prio: 11,
        title: "knnections",
        desc: `Trained a model using ${EMPH}NumPy,${EMPH} ${EMPH}PyTorch${EMPH} and ${EMPH}sklearn${EMPH} to play NYT connections with ${EMPH}85%${EMPH} guess accuracy if it already knows the categories and ${EMPH}24%${EMPH} guess accuracy if it doesn't, ${EMPH}surpassing${EMPH} ${EMPH}GPT-3.5${EMPH}. Uses ${EMPH}PCA/SVD${EMPH}, ${EMPH}autoencoding${EMPH} and ${EMPH}k-means${EMPH} clustering algorithm to reduce the ${EMPH}dimensionality${EMPH} and ${EMPH}cluster${EMPH} vectorized game words.`,
        link: 'https://github.com/NlSEMONO/knnections',
        img: knnImg
    }
]

function endPunc(word) {
    if (word.endsWith(".") || word.endsWith(",") || word.endsWith("!") || word.endsWith("?")) {
        return true;
    }
}

function generateCard(proj) {
    let words = proj.desc.split(" ");
    let emphClasses ="text-black inline-block pr-1 font-text text-xl";
    let defClasses ="inline-block pr-1 font-text text-xl";
    let wordsMap = words.map((word) => {
        if (word.startsWith(EMPH)) {
            let removeTail = 1;
            let newTail = "";
            if (endPunc(word)) {
                removeTail++;
                newTail = word[word.length - 1];
            }

            return <span className={emphClasses} >{word.substring(1, word.length - removeTail) + newTail + " "}</span>
        }
        return <p className={defClasses}> {word+" "} </p>;
    })
    return (
        <div className="font-titles text-main2 text-center"> 
            <h2 className="text-3xl font-bold mt-16 md:mt-0"> {proj.title} </h2> <br/>
            <i className="">{wordsMap}</i>
        </div>
    )
}

function Projects() {
    PROJECTS.sort((a, b) => b.prio - a.prio);
    let cards = PROJECTS.map((proj, i) => {
        // if (i % 2 == 0) {
        //     return (
        //         <div className="md:items-start p-4 mt-16 flex justify-between flex-wrap">
        //             <a href={proj.link} target="_blank" rel="noreferrer" className="md:max-w-[50%] text-center">
        //                 {generateCard(proj)}
        //             </a>
        //             <a href={proj.link} className='w-11/12 min-w-72 h-72 sm:w-10/12 md:w-3/12 lg:h-[400px] lg:min-w-[450px] border-[#c0c0c0]'>
        //                 <img src={proj.img} className='w-11/12 min-w-72 h-72 sm:w-10/12 md:w-3/12 lg:h-[400px] lg:min-w-[450px] border-[#c0c0c0] border-solid border-4 object-cover rounded-3xl m-auto mt-5 md:m-0'/>
        //             </a>
        //         </div>
        //     )
        // }
        return (
            <div className="md:items-start p-4 mt-8 flex justify-between flex-wrap">
                <a href={proj.link} className='w-10/12 min-w-64 h-72 sm:w-10/12 md:w-3/12 lg:h-[400px] lg:min-w-[350px] xl:min-w-[450px] border-[#c0c0c0]'>
                    <img src={proj.img} className='w-10/12 min-w-64 h-72 sm:w-10/12 md:w-3/12 lg:h-[400px] lg:min-w-[350px] xl:min-w-[450px] border-[#c0c0c0] border-solid border-4 object-cover rounded-3xl m-auto mt-5 md:m-0'/>
                </a>
                <a href={proj.link} target="_blank" rel="noreferrer" className="md:max-w-[50%] text-center">
                    {generateCard(proj)}
                </a>
            </div>
        )
    });
    return (
        <div id="projects" className="flex flex-col items-center justify-center bg-comp1 text-center">
            <div className='w-[80%]'> 
                <h1 className="font-titles text-main2 text-6xl text-bold mt-12"> Projects </h1>
                <div className="">
                    {cards}
                </div>
            </div>
        </div>
    )
}

export default Projects;
